// path-to-your-redux-store.js
import { createStore, combineReducers } from 'redux';
import themeReducer from '../reducer/Reducer';

const rootReducer = combineReducers({
  theme: themeReducer,
});

const store = createStore(rootReducer);

export default store;
