import React, { Fragment } from 'react'
// import dataBlog from '../assets/fake-data/data-blog';

import { Link } from 'react-router-dom';
import Blog from '../assets/fake-data/data-blog';
const BlogSection = () => {
    return (
        <Fragment>
            <section className="blog-grid">
                <div className="container">
                    <div className='blogs-heading'>
                        <h1>Blogs</h1>
                    </div>
                    <div className="row">
                        <div className="col-md-12 blog-cards-flex gap-4">
                            {
                                Blog.map(idx => (
                                    <div key={idx.id} className="col-md-3 col-sm-12 ">
                                        <div className="blog-box">
                                            <div className="box-image">
                                                <img src={idx.img} alt="Rockie" />
                                                {/* <div className="wrap-video">
                                                    <Link
                                                        to="#"
                                                        className="popup-youtube"
                                                        onClick={() => setOpen(true)}
                                                    >
                                                        <svg
                                                            width="13"
                                                            height="16"
                                                            viewBox="0 0 13 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M0.466675 2.92407C0.466675 1.35705 2.187 0.398733 3.51938 1.22354L11.7197 6.2999C12.9827 7.0818 12.9827 8.91907 11.7197 9.70096L3.51938 14.7773C2.187 15.6021 0.466675 14.6438 0.466675 13.0768V2.92407Z"
                                                                fill="#777E90"
                                                            />
                                                        </svg>
                                                    </Link>
                                                </div> */}
                                            </div>

                                            <div className="box-content">
                                                <Link to="#" className="category btn-action">{idx.category}</Link>
                                                <Link to="#" className=" blog-title"
                                                >{idx.title}</Link>

                                                <div className="meta">
                                                    <Link to="#" className="name"
                                                    ><span></span>{idx.user}</Link>
                                                    <Link to="#" className="time">{idx.time}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default BlogSection