

import img1 from '../images/blog/blog-01.jpg';
import img2 from '../images/blog/blog-02.jpg';
import img3 from '../images/Exchange/exch1.jpg';
import img4 from '../images/Exchange/exch2.jpg';
import img5 from '../images/icon/Mining.png';
import img6 from '../images/icon/Wallet.png';


 const dataBlog = [
    {
        id: 1,
        img: img1,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 2,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 3,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 4,
        img: img1,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021'
    },
    {
        id: 5,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021'
    },
    {
        id: 6,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021'
    },
   
]
  const Blog = [
    {
        id: 1,
        img: img3,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 2,
        img: img4,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 3,
        img: img3,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 4,
        img: img4,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021'
    },

]
export default Blog;

