import React, { useEffect, useRef, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../../Store/action/Action';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';


Coinlist01.propTypes = {
    dataCoin: PropTypes.array,
};

function Coinlist01(props) {

    const { dataCoin } = props;
    const [them, setThem] = useState('dark');
    const [dataCoinTab] = useState([
        {
            id: 1,
            title: 'View All',
        },
        {
            id: 2,
            title: 'Metaverse',
        },
        {
            id: 3,
            title: 'Entertainment',
        },
        {
            id: 4,
            title: 'Energy',
        },
        {
            id: 5,
            title: 'NFT',
        },
        {
            id: 6,
            title: 'Gaming',
        },
        {
            id: 7,
            title: 'Music',
        },
    ]);
    const container = useRef();
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme); // Read theme from Redux store
    console.log("lll", theme);
    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme === 'is_light' || storedTheme === 'is_dark') {
            dispatch(toggleTheme()); // Dispatch action to toggle theme in Redux store
        }
    }, [dispatch]);

    const currentTheme = theme.theme === 'is_light' ? 'light' : 'dark';
    console.log("currentTheme", currentTheme);
    // useEffect(() => {
      
    // }, [theme]);
    // const body = document.body
    // const lightTheme = "is_light"
    // const darkTheme = "is_dark"
    // let theme

    // useEffect(() => {
    //     const storedTheme = localStorage.getItem('theme');
    //     if (storedTheme === 'is_light' || storedTheme === 'is_dark') {
    //         dispatch(toggleTheme()); // Dispatch action to toggle theme in Redux store
    //     }
    // }, [dispatch]);
    // setThem(theme === 'is_light' ? 'light' : 'dark');
    // Check the current values of the classList
    // const currentClasses = body.classList;

    // const [them, setThem] = useState('dark');
    // useEffect(() => {
    //     // Check the current values of the classList
    //     const currentClasses = body.classList;
    //     console.log('current body', currentClasses);

    //     const storedTheme = localStorage.getItem('theme');
    //     if (storedTheme === 'is_light' || storedTheme === 'is_dark') {
    //       setThem(storedTheme === 'is_light' ? 'light' : 'dark');
    //     }
    //   }, [body.classList]);
    //   useEffect(() => {
    //     // Additional logic or side effects when the theme changes
    //     // You can place any logic here that needs to run when the theme changes
    //     console.log("new");
    //   }, [theme]);
    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    //     script.type = "text/javascript";
    //     script.async = true;
    //     script.innerHTML = `
    //     {
    //       "symbols": [

    //         [
    //             "Bitcoin",
    //             "BTCUSD|1D"
    //           ],
    //         [
    //           "Microsoft",
    //           "MSFT|1D"
    //         ]
    //       ],
    //       "chartOnly": false,
    //       "width": 1400,
    //       "height": 500,
    //       "locale": "en",
    //       "colorTheme": "dark",
    //       "autosize": false,
    //       "showVolume": false,
    //       "showMA": false,
    //       "hideDateRanges": false,
    //       "hideMarketStatus": false,
    //       "hideSymbolLogo": false,
    //       "scalePosition": "right",
    //       "scaleMode": "Normal",
    //       "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
    //       "fontSize": "10",
    //       "noTimeScale": false,
    //       "valuesTracking": "1",
    //       "changeMode": "price-and-percent",
    //       "chartType": "area",
    //       "maLineColor": "#2962FF",
    //       "maLineWidth": 1,
    //       "maLength": 9,
    //       "lineWidth": 2,
    //       "lineType": 0,
    //       "dateRanges": [
    //         "1d|60",
    //         "1m|30",
    //         "3m|60",
    //         "12m|1D",
    //         "60m|1W",
    //         "all|1M"
    //       ]
    //     }`;
    //     container.current.appendChild(script);
    // }, []);
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js';
    //     script.async = true;

    //     const config = {
    //         width: 1450,
    //         height: 1100,
    //         symbolsGroups: [
    //             {
    //                 name: 'Indices',
    //                 originalName: 'Indices',
    //                 symbols: [
    //                     { name: 'FOREXCOM:SPXUSD', displayName: 'S&P 500' },
    //                     { name: 'FOREXCOM:NSXUSD', displayName: 'US 100' },
    //                     { name: 'FOREXCOM:DJI', displayName: 'Dow 30' },
    //                     { name: 'INDEX:NKY', displayName: 'Nikkei 225' },
    //                     { name: 'INDEX:DEU40', displayName: 'DAX Index' },
    //                     { name: 'FOREXCOM:UKXGBP', displayName: 'UK 100' },
    //                 ],
    //             },
    //             {
    //                 name: 'Futures',
    //                 originalName: 'Futures',
    //                 symbols: [
    //                     { name: 'CME_MINI:ES1!', displayName: 'S&P 500' },
    //                     { name: 'CME:6E1!', displayName: 'Euro' },
    //                     { name: 'COMEX:GC1!', displayName: 'Gold' },
    //                     { name: 'NYMEX:CL1!', displayName: 'WTI Crude Oil' },
    //                     { name: 'NYMEX:NG1!', displayName: 'Gas' },
    //                     { name: 'CBOT:ZC1!', displayName: 'Corn' },
    //                 ],
    //             },
    //             {
    //                 name: 'Bonds',
    //                 originalName: 'Bonds',
    //                 symbols: [
    //                     { name: 'CBOT:ZB1!', displayName: 'T-Bond' },
    //                     { name: 'CBOT:UB1!', displayName: 'Ultra T-Bond' },
    //                     { name: 'EUREX:FGBL1!', displayName: 'Euro Bund' },
    //                     { name: 'EUREX:FBTP1!', displayName: 'Euro BTP' },
    //                     { name: 'EUREX:FGBM1!', displayName: 'Euro BOBL' },
    //                 ],
    //             },
    //             {
    //                 name: 'Forex',
    //                 originalName: 'Forex',
    //                 symbols: [
    //                     { name: 'FX:EURUSD', displayName: 'EUR to USD' },
    //                     { name: 'FX:GBPUSD', displayName: 'GBP to USD' },
    //                     { name: 'FX:USDJPY', displayName: 'USD to JPY' },
    //                     { name: 'FX:USDCHF', displayName: 'USD to CHF' },
    //                     { name: 'FX:AUDUSD', displayName: 'AUD to USD' },
    //                     { name: 'FX:USDCAD', displayName: 'USD to CAD' },
    //                 ],
    //             },
    //         ],
    //         showSymbolLogo: true,
    //         isTransparent: false,
    //         colorTheme: 'light',
    //         locale: 'en',
    //     };

    //     script.text = `{
    //       "width": ${config.width},
    //       "height": ${config.height},
    //       "symbolsGroups": ${JSON.stringify(config.symbolsGroups)},
    //       "showSymbolLogo": ${config.showSymbolLogo},
    //       "isTransparent": ${config.isTransparent},
    //       "colorTheme": "${config.colorTheme}",
    //       "locale": "${config.locale}"
    //     }`;

    //     const container = document.querySelector('.tradingview-widget-container__widget');
    //     container.innerHTML = '';
    //     container.appendChild(script);

    //     return () => {
    //         container.innerHTML = '';
    //     };
    // }, []);


    return (
        <section className="coin-list">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text">
                            <h3 className="heading">Market Update</h3>
                            <Link to="#" className="btn-action-2" >See All Coins</Link>
                        </div>

                        <div className="coin-list__main">
                            <Tabs>
                                {/* <TabList>
                                    {
                                        dataCoinTab.map(idx => (
                                            <Tab key={idx.id}>{idx.title}</Tab>
                                        ))
                                    }

                                </TabList> */}

                                {/* {
                                dataCoinTab.map(data => (
                                    <TabPanel key={data.id}>
                                        <div className="content-inner">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Last Price</th>
                                                    <th scope="col">24h %</th>
                                                    <th scope="col">Market Cap</th>
                                                    <th scope="col">Last 7 Days</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    dataCoin.map(idx => (
                                                        <tr key={idx.id}>
                                                            <td ><span className="icon-star"></span></td>
                                                            <td>{idx.id}</td>
                                                            <td>
                                                                <Link to="#">
                                                            <img src={idx.icon} alt="rockie" />
                                                                <span>{idx.name}</span>
                                                                <span className="unit">{idx.unit}</span></Link>
                                                            </td>
                                                            <td className="boild">{idx.price}</td>
                                                            <td className={`${idx.class}`}>{idx.sale}</td>
                                                            <td className="boild">{idx.cap}</td>
                                                            <td><img src={idx.chart} alt="Rockie" /></td>
                                                            <td><Link to="#" className="btn">Trade</Link></td>
                                                        </tr>
                                                    ))
                                                }
                                                
                                            </tbody>
                                            </table>
                                        </div>
                                    </TabPanel>
                                ))
                            } */}

                                {/* <div class="tradingview-widget-container">
                                    <div class="tradingview-widget-container__widget"></div>
                                    <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a></div>
                                    <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-screener.js" async>
                                        {
                                            "width": 1000,
                                        "height": 490,
                                        "defaultColumn": "overview",
                                        "screener_type": "crypto_mkt",
                                        "displayCurrency": "USD",
                                        "colorTheme": "light",
                                        "locale": "en"
}
                                    </script>
                                </div> */}
                                <div>
                                    {/* <TradingViewWidget
        
        height={490}
        defaultColumn="overview"
        screenerType="market-quotes"
        displayCurrency="USD"
        colorTheme={Themes.LIGHT}
        locale="en"
      /> */}


                                    {/* <div className="tradingview-widget-container w-full">
                                        <div className="tradingview-widget-container__widget "></div>
                                        <div className="tradingview-widget-copyright">

                                        </div>
                                    </div> */}
                                    {/* <div className="tradingview-widget-container" ref={container}>
                                        <div className="tradingview-widget-container__widget"></div>
                                        <div className="tradingview-widget-copyright">

                                        </div>
                                    </div> */}
                                    <div>
                                        <iframe width="100%" height="1000" src={`https://coinbrain.com/embed/bnb-0xd16cb89f621820bc19dae1c29c9db6d22813b01d?theme=${currentTheme}&padding=12&chart=1&trades=1`}></iframe>
                                    </div>

                                </div>



                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Coinlist01;
