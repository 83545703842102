// DarkMode.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleTheme } from '../../Store/action/Action';
import imgsun from '../../assets/images/icon/sun.png'
import imgmoon from '../../assets/images/icon/moon.png'
const DarkMode = () => {
  const dispatch = useDispatch();
  let clickedClass = "clicked";
  const body = document.body;
  const lightTheme = "is_light";
  const darkTheme = "is_dark";

  let theme;
  if (localStorage) {
    theme = localStorage.getItem("theme");
  }

  if (theme === lightTheme || theme === darkTheme) {
    body.classList.add(theme);
  } else {
    body.classList.add(darkTheme);
  }

  const switchTheme = () => {
    if (theme === darkTheme) {
      body.classList.replace(darkTheme, lightTheme);
      localStorage.setItem("theme", "is_light");
      theme = lightTheme;
    } else {
      body.classList.replace(lightTheme, darkTheme);
      localStorage.setItem("theme", "is_dark");
      theme = darkTheme;
    }

    // Dispatch the toggleTheme action using useDispatch hook
    dispatch(toggleTheme());
  };

  return (
    <div className="mode-switcher">
      <Link className={`sun ${theme === 'is_light' ? '' : clickedClass}`} to="#" onClick={switchTheme}>
        <img src={imgsun} alt="" />
      </Link>
      <Link className={`moon ${theme === 'is_dark' ? clickedClass : ''}`} to="#" onClick={switchTheme}>
        <img src={imgmoon} alt="" />
      </Link>
    </div>
  );
}

export default DarkMode;
