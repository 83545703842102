// path-to-your-theme-reducer.js
const initialState = {
  theme: localStorage.getItem('theme') || 'is_dark',
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME':
      const newTheme = state.theme === 'is_light' ? 'is_dark' : 'is_light';
      localStorage.setItem('theme', newTheme);
      return {
        ...state,
        theme: newTheme,
      };
    default:
      return state;
  }
};

export default themeReducer;
