import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link, NavLink } from 'react-router-dom';

// import img from '../../assets/images/logo/log-footer.png'
// import img1 from '../../assets/images/logo/logo-footer-dark.png'

import img from '../../assets/images/logo/logo.png'
import img1 from '../../assets/images/logo/logo.png'
///coin icons
import ada from '../../assets/images/coin/ada.png'
import bnd from '../../assets/images/coin/bnb.png'
import btc from '../../assets/images/coin/btc.png'
import eth from '../../assets/images/coin/eth.png'
import avax from '../../assets/images/coin/avax.png'
import coin from '../../assets/images/icon/icon-01.png'
import bakreycoin from '../../assets/images/icon/bakeryswap.svg'
import poocoin from '../../assets/images/icon/poocoin-1.svg'
import pancake from '../../assets/images/icon/pancake.svg'
import pdf from '../../pdf/Coinbidex.pdf'
import samplePDF1 from "../../pdf/Coinbidex.pdf";
function Footer(props) {




    const [productLink] = useState([
        {
            title: 'Exchange',
            path: '#'
        },
        {
            title: ' Credit Card',
            path: '#'
        },
        {
            title: ' Active Trader',
            path: '#'
        },
        {
            title: 'Mobile',
            path: '#'
        }, {
            title: 'Derivative',
            path: '#'
        },
    ]);




    const [servicesLink] = useState([
        {
            title: 'About',
            path: '#'
        },
        {
            title: 'carreers',
            path: '#'
        },
        {
            title: 'Affiliates',
            path: '#'
        },
        {
            title: 'Security',
            path: '#'
        },

    ]);

    const [legal] = useState([
        // {
        //     title: 'Legal',
        //     path: '#'
        // },
        {
            title: 'Agreements',
            path: '#'
        },
        {
            title: 'Privacy and Data Policy',
            path: '#'
        },
        {
            title: 'Cookie Policy',
            path: '#'
        },
        {
            title: 'Important',
            path: '#'
        },

    ]);
    const [Resources] = useState([
        {
            title: 'Audit',
            path: 'https://app.solidproof.io/projects/coinbidex'
        },
        {
            title: 'White Paper',
            path: '../../pdf/Coinbidex.pdf'
        },
        {
            title: 'Token Creation',
            path: 'https://tokencreator.coinbidex.com/'
        },

    ]);



    const [listSocial] = useState([
        {
            icon: 'icon-facebook-f',
            path: '#'
        },
        {
            icon: 'icon-instagram',
            path: '#'
        },
        {
            icon: 'icon-youtube',
            path: '#'
        },
        {
            icon: 'icon-twitter',
            path: '#'
        },
    ])
    const menus = [


        {
            id: 1,
            name: 'Trade',
            links: '#',
            namesub: [
                {
                    id: 1,
                    sub: 'Coinbidex',
                    links: 'https://swap.coinbidex.com/',
                    icon: coin
                },
                {
                    id: 2,
                    sub: 'Coinbrain',
                    links: 'https://coinbrain.com/',
                    icon: ada
                },
                {
                    id: 3,
                    sub: 'Poocoin',
                    links: 'https://poocoin.app/tokens/0xd16cb89f621820bc19dae1c29c9db6d22813b01d',
                    icon: poocoin
                },
                {
                    id: 4,
                    sub: 'BakerySwap',
                    links: 'https://www.bakeryswap.org/#/swap',
                    icon: bakreycoin
                },
                {
                    id: 5,
                    sub: 'Xeggex',
                    links: 'https://xeggex.com/market/CBE_USDT',
                    icon: eth
                },
                {
                    id: 6,
                    sub: 'Pancakeswap',
                    links: 'https://pancakeswap.finance/',
                    icon: pancake
                },
            ],
        },


    ]

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer style-2">
            <div className="container">
                <div className="footer__main">
                    <div className="row">
                        <div className="col-xl-3 col-md-3">
                            <div className="info">
                                {/* <Link to="/" className="logo light">
                                    <img src={img} alt="" />
                                </Link>
                                <Link to="/" className="logo dark">
                                    <img src={img1} alt="" />
                                </Link> */}
                                <div className="widget-link s2">
                                    <h6 className="title">Legal</h6>
                                    <ul>
                                        {
                                            legal.map((data, idx) => (
                                                <li key={idx}><Link to={data.path}>{data.title}</Link></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                {/* <p>The World Is Growing Faster And Getting Advanced Day By Day, But In The Era Of Digitalization And Technology, The Problem For The Users Are Increasing Daily. 🤙</p> */}
                                {/* <ul className="list">
                                    <li><p>+92 345 678 </p></li>
                                    <li><p>Info.Avitex@Gmail.Com</p></li>
                                    <li>
                                        <p>

                                        </p>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <div className="widget">
                                <div className="widget-link">
                                    <h6 className="title">PRODUCTS</h6>
                                    <ul>
                                        {/* <a href={samplePDF1} target="_blank"
                                            rel="noreferrer">
                                            Open First PDF
                                        </a> */}
                                        {productLink.map((data, idx) => (
                                            <li key={idx}>
                                                {/* {data.path.endsWith('.pdf') ? (

                                                    <a href={samplePDF1} target="_blank"
                                                        rel="noreferrer">
                                                        {data.title}
                                                    </a>
                                                ) : (
                                                    )} */}
                                                <Link to={data.path}>{data.title}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="widget-link s2">
                                    <h6 className="title">COMPANY</h6>
                                    <ul>
                                        {
                                            servicesLink.map((data, idx) => (
                                                <li key={idx}><Link to={data.path}>{data.title}</Link></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="widget-link s2">
                                    <h6 className="title">Resources</h6>
                                    <ul>
                                        {
                                            Resources.map((data, idx) => (

                                                <li key={idx}>
                                                    {data.path.endsWith('.pdf') ? (

                                                        <a href={samplePDF1} target="_blank"
                                                            rel="noreferrer">
                                                            {data.title}
                                                        </a>
                                                    ) : (
                                                        // <Link to={data.path}>{data.title}</Link>
                                                    
                                                        <Link to={data.path} target="_blank" rel="noopener noreferrer">{data.title}</Link>
                                                    )}

                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            {/* <div className="footer-contact">
                                <h5>Newletters</h5>
                                <p>
                                    Subscribe our newsletter to get more free design course and
                                    resource.
                                </p>
                                <form action="#">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        required=""
                                    />
                                    <button type="submit" className="btn-action">Submit</button>
                                </form>
                                <ul className="list-social">
                                    {
                                        listSocial.map((data, idx) => (
                                            <li key={idx}>
                                                <Link to={data.path}><span className={data.icon}></span></Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div> */}
                            <div className="widget-link s2">
                                <div className="footer-contact">

                                    <h6 className="title mx-3">Trades</h6>
                                    <ul>
                                        {menus.map((data, idx) => (
                                            <li key={idx}

                                            >
                                                {/* <Link to={data.links}>{data.name}</Link> */}
                                                {
                                                    data.namesub &&
                                                    <ul className="sub-menu">
                                                        {
                                                            data.namesub.map((submenu) => (
                                                                <li key={submenu.id} className="menu-item ">
                                                                    <div className='submenu-main-div '>
                                                                        <div className='submenu-div'>
                                                                            <div className='submenu-div-1'>
                                                                                {submenu.icon && <img src={submenu.icon} className='submenu-icon' />}
                                                                            </div>
                                                                            {/* <NavLink to={submenu.links} className="">
                                                                                        {submenu.sub}
                                                                                    </NavLink> */}
                                                                            <div className='submenu-div-2'>
                                                                                <NavLink
                                                                                    // to={submenu.links}
                                                                                    className=""
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        if (submenu.links) {

                                                                                            window.open(submenu.links, '_blank');
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {submenu.sub}
                                                                                </NavLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                // <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}><img src={submenu.icon} alt="" />{submenu.sub}</NavLink></li>
                                                            ))
                                                        }
                                                    </ul>
                                                }

                                            </li>
                                        ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="footer__bottom">
                    <p>
                        ©2024 Coinbidex. All rights reserved. Terms of Service | Privacy
                        Terms
                    </p>
                </div>
            </div>

            {
                isVisible &&
                <Link onClick={scrollToTop} to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;