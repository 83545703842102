import React, { Fragment, useEffect, useState } from 'react';

import { TickerTape } from 'react-tradingview-widget';


import Banner01 from '../components/banner/Banner01';
import Crypto01 from '../components/crypto/Crypto01';
import Coinlist01 from '../components/coinlist/Coinlist01';
import Work01 from '../components/work/Work01';
import About01 from '../components/about/About01';
import About03 from '../components/about/About03';
import About02 from '../components/about/About02';
import Testimonial01 from '../components/testimonial/Testimonial01';
import Sale01 from '../components/sale/Sale01';
import dataPartner from '../assets/fake-data/data-partner';
import dataTestimonial from '../assets/fake-data/data-testimonial';
import dataWork from '../assets/fake-data/data-work';
import dataCoin from '../assets/fake-data/data-coin';
import Download01 from '../components/download/Download01';
import SwapPage from '../components/SwipPage'
import Header from '../components/header';
import Footer from '../components/footer';
import BlogSection from './BlogSection'
import dataAbout from '../assets/fake-data/data-about';
function HomeOne(props) {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.async = true;

        const config = {
            symbols: [
                { proName: 'FOREXCOM:SPXUSD', title: 'S&P 500' },
                { proName: 'FOREXCOM:NSXUSD', title: 'US 100' },
                { proName: 'FX_IDC:EURUSD', title: 'EUR to USD' },
                { proName: 'BITSTAMP:BTCUSD', title: 'Bitcoin' },
                { proName: 'BITSTAMP:ETHUSD', title: 'Ethereum' },
            ],
            showSymbolLogo: true,
            isTransparent: false,
            displayMode: 'adaptive',
            colorTheme: 'light',
            locale: 'en',
        };

        script.text = `{
          "symbols": ${JSON.stringify(config.symbols)},
          "showSymbolLogo": ${config.showSymbolLogo},
          "isTransparent": ${config.isTransparent},
          "displayMode": "${config.displayMode}",
          "colorTheme": "${config.colorTheme}",
          "locale": "${config.locale}"
        }`;

        const container = document.querySelector('.custom-tradingview-widget-containers__widget');
        container.innerHTML = '';
        container.appendChild(script);

        return () => {
            container.innerHTML = '';
        };
    }, []);
    return (
        <Fragment>
            <Header />



            <div className='relative'>


                <div className='home-1'>

                    <Banner01 data={dataPartner} />

                    {/* <Crypto01 /> */}
                    {/* <About02/> */}
                    <About02 data={dataAbout} />
                    <Coinlist01 dataCoin={dataCoin} />

                    {/* <Work01 data={dataWork} /> */}

                    <About03 />
                    <About01 />
                    <BlogSection />
                    {/* <SwapPage /> */}

                    {/* <Download01 /> */}

                    {/* <Testimonial01 data={dataTestimonial} /> */}

                    <Sale01 />

                </div>

                <div className="custom-tradingview-widget-containers main-traker">
                    <div className="custom-tradingview-widget-containers__widget"></div>
                    <div className="custom-tradingview-widget-copyright">

                    </div>
                </div>

            </div>
            <Footer />
        </Fragment>
    );
}

export default HomeOne;