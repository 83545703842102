import React, { Fragment, useEffect, useState } from 'react'
import './Slider.css';
import ada from '../../assets/images/coin/ada.png'
import avx from '../../assets/images/coin/avax.png'
import bnb from '../../assets/images/coin/bnb-2.png'
import btc from '../../assets/images/coin/btc-2.png'

////
import iconheading from '../../assets/images/star-twinkle.0a2f3476.svg'


//icon
import icon1 from '../../assets/swapicon/icon-audiobook.0f928982.svg'
import icon2 from '../../assets/swapicon/icon-book.48f825ef.svg'
import icon3 from '../../assets/swapicon/icon-chatgpt.2bc83055.svg'
import icon4 from '../../assets/swapicon/icon-coaching.72458114.svg'
import icon5 from '../../assets/swapicon/icon-comic.8110f8b3.svg'
import icon6 from '../../assets/swapicon/icon-drawing.34aec43e.svg'
import icon7 from '../../assets/swapicon/icon-image.138baf9a.svg'
import icon8 from '../../assets/swapicon/icon-instagram.3d396293.svg'
import icon9 from '../../assets/swapicon/icon-kickstarter.b497f9b1.svg'
import icon10 from '../../assets/swapicon/icon-link.9ebb9410.svg'
import icon11 from '../../assets/swapicon/icon-livestream.a612cf1e.svg'
import icon12 from '../../assets/swapicon/icon-membership.b8b0dd2e.svg'
import icon13 from '../../assets/swapicon/icon-music-cd.a8fbc0ba.svg'
import icon14 from '../../assets/swapicon/icon-music-filter.c0b8d14b.svg'
import icon15 from '../../assets/swapicon/icon-pinterest.4fd53c1d.svg'
import icon16 from '../../assets/swapicon/icon-rss.8e8dbfef.svg'
import icon17 from '../../assets/swapicon/icon-shopify.2ea46655.svg'
import icon18 from '../../assets/swapicon/icon-software.bdd1bf02.svg'
import icon19 from '../../assets/swapicon/icon-wix.9c6c427d.svg'
import icon20 from '../../assets/swapicon/icon-wordpress.6a74afbd.svg'
import icon21 from '../../assets/swapicon/icon-x.18a05337.svg'

import dataServices2 from '../../assets/fake-data/data-services-2';

import icon_pay from '../../assets/images/icon/icon-pay (1).svg'
import icon_cube from '../../assets/images/icon/icon-3d-cube (1).svg'
import icon_chart from '../../assets/images/icon/icon-chart (1).svg'
import icon_card from '../../assets/images/icon/icon-cards (1).svg'
import icon_wand from '../../assets/images/icon/icon-wand (1).svg'
import Services02 from '../../components/services/Services02';
import Header from '../header';
import Footer from '../footer';


//coin
import icon23 from '../../assets/images/coin/DOGE.svg'
import icon24 from '../../assets/images/coin/binance.svg'
import icon25 from '../../assets/images/coin/bitcoin_1.svg'
import icon26 from '../../assets/images/coin/bitcoin_2.svg'
import icon27 from '../../assets/images/coin/eth-2.png'
import icon28 from '../../assets/images/coin/busd.svg'
import icon29 from '../../assets/images/coin/cgpt.svg'
import icon30 from '../../assets/images/coin/etherium.svg'
import icon31 from '../../assets/images/coin/litecoin.svg'
import icon32 from '../../assets/images/coin/polygon.svg'
import icon33 from '../../assets/images/coin/sol.png'
import icon34 from '../../assets/images/coin/solano.svg'
import icon35 from '../../assets/images/coin/tet-2.png'
import icon36 from '../../assets/images/coin/tether.svg'
import icon37 from '../../assets/images/coin/trx.svg'
import icon38 from '../../assets/images/coin/usdc.svg'
import icon39 from '../../assets/images/coin/xmr.svg'



import icon40 from '../../assets/swapicon/newcoin/DOGE.svg'
import icon41 from '../../assets/swapicon/newcoin/axie.png'
import icon42 from '../../assets/swapicon/newcoin/binance.svg'
import icon43 from '../../assets/swapicon/newcoin/bitcoin_1.svg'
import icon44 from '../../assets/swapicon/newcoin/busd.svg'
import icon45 from '../../assets/swapicon/newcoin/cgpt.svg'
import icon46 from '../../assets/swapicon/newcoin/crpto1.png'
import icon47 from '../../assets/swapicon/newcoin/dai.svg'
import icon48 from '../../assets/swapicon/newcoin/dash.svg'
import icon49 from '../../assets/swapicon/newcoin/dogecoin.png'
import icon50 from '../../assets/swapicon/newcoin/eos.png'
import icon51 from '../../assets/swapicon/newcoin/etherium.svg'
import icon52 from '../../assets/swapicon/newcoin/litecoin.svg'
import icon53 from '../../assets/swapicon/newcoin/pancakeswap.png'
import icon54 from '../../assets/swapicon/newcoin/polygon.svg'
import icon55 from '../../assets/swapicon/newcoin/ripple.png'
import icon56 from '../../assets/swapicon/newcoin/safemoon.png'
import icon57 from '../../assets/swapicon/newcoin/solano.svg'
import icon58 from '../../assets/swapicon/newcoin/tether.svg'
import icon59 from '../../assets/swapicon/newcoin/ton.svg'
import icon60 from '../../assets/swapicon/newcoin/ton.svg'
import icon61 from '../../assets/swapicon/newcoin/ton.svg'
import icon62 from '../../assets/swapicon/newcoin/xmr.svg'
import icon63 from '../../assets/swapicon/newcoin/tether-usdt-seeklogo.svg'





const Index = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [coinIndex, setCoinIndex] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 1000); // Adjust the interval as needed (lower values for smoother animation)

        return () => clearInterval(intervalId);
    }, []);
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setCoinIndex((prevIndex) => (prevIndex + 1) % icons.length);
    //     }, 1000); // Adjust the interval as needed (lower values for smoother animation)

    //     // return () => clearInterval(intervalId);
    // }, [coinIndex]);
    // inting
    // Audiobooks
    // Software
    // Games
    // Videos
    // Writing & publishing
    // Comics & graphic novels
    // Design
    // Drawing & painting
    // Education
    // Music
    // Coaching services
    // Fitness training guides
    // Data
    // Fiction books
    // Fonts
    // Nonfiction books
    // Photography
    // Meal plans
    // Music albums
    // Workout plans
    // Music beats
    // Custom jingles
    // Graphic designs
    // ChatGPT plugins
    // Event tickets
    // Registration codes
    // Templates
    // 3D printing
    // Audiobooks
    // Software
    // Games
    // Videos
    // Writing & publishing
    // Comics & graphic novels
    // Design
    // Drawing & painting
    // Education
    // Music
    // Coaching services
    // Fitness training guides
    // Data
    // Fiction books
    // Fonts
    // Nonfiction books
    // Photography
    // Meal plans
    // Music albums
    // Workout plans
    // Music beats
    // Custom jingles
    // Graphic designs
    // ChatGPT plugins
    // Event tickets
    // Registration codes
    // Anyway you sell
    // Ebooks
    // PDFs
    // Music
    // Online courses
    // Livestreams
    // Presale
    // Kickstarter
    // Tipjar
    // Newsletters
    // Memberships
    // Zip files
    // How-to guides
    // Subscriptions
    // Online services
    // Game codes
    // Audio streaming
    // Audio files
    // Podcast
    // Visual
    // Notion templates
    // Recipes
    // Movies
    // Images
    // RSS feeds
    // URLs
    // Bundles
    // Drip files
    // Ebooks
    // PDFs
    // Music
    // Online courses
    // Livestreams
    // Presale
    // Kickstarter
    // Tipjar
    // Newsletters
    // Memberships
    // Zip files
    // How-to guides
    // Subscriptions
    // Online services
    // Game codes
    // Audio files
    // Podcast
    // Visual
    // Recipes
    // Movies
    // Images
    // RSS feeds
    // URLs
    // Drip file
    // Photography
    // Your website
    // Your blog
    // Payment links
    // X (Twitter)
    // Instagram
    // TikTok
    // YouTube
    // Pinterest
    // Facebook
    // Linktree
    // Stripe checkout
    // Shopify
    // Notion 
    // Webflow
    // Wix
    // WordPress
    // Instagram


    // function shuffleArray(array) {
    //     const shuffledArray = [...array];
    //     for (let i = shuffledArray.length - 1; i > 0; i--) {
    //         const j = Math.floor(Math.random() * (i + 1));
    //         [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    //     }
    //     return shuffledArray;
    // }
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    const items = [

        {
            id: 1,
            name: 'WordPress',
            icon: icon1
        },
        {
            id: 2,
            name: 'Wix',
            icon: icon2
        },
        {
            id: 3,
            name: 'Webflow',
            icon: icon6
        },
        {
            id: 4,
            name: 'Squarespace',
            icon: icon4
        },
        {
            id: 5,
            name: 'Notion',
            icon: icon5
        },
        {
            id: 6,
            name: 'Shopify',
            icon: icon6
        },
        {
            id: 7,
            name: 'software',
            icon: icon7
        },
        {
            id: 8,
            name: 'Instagram',
            icon: icon8
        },
        {
            id: 9,
            name: ' Stripe checkout',
            icon: icon9
        },
        {
            id: 10,
            name: 'Linktree',
            icon: icon10
        },
        {
            id: 11,
            name: 'Facebook',
            icon: icon11
        },
        {
            id: 12,
            name: 'Pinterest',
            icon: icon12
        },
        {
            id: 13,
            name: 'YouTube',
            icon: icon13
        },
        {
            id: 14,
            name: 'software',
            icon: icon14
        },
        {
            id: 15,
            name: 'TikTok',
            icon: icon15
        },
        {
            id: 16,
            name: ' X (Twitter)',
            icon: icon16
        },
        {
            id: 17,
            name: 'Payment links',
            icon: icon17
        },
        {
            id: 18,
            name: 'Your blog',
            icon: icon18
        },
        {
            id: 19,
            name: 'Your website',
            icon: icon19
        },
        {
            id: 20,
            name: 'WhatsApp',
            icon: icon20
        },
        {
            id: 21,
            name: 'Anywhere you sell',
            icon: icon1
        },
        {
            id: 22,
            name: 'Notion templates',
            icon: icon2
        },
        {
            id: 23,
            name: 'Photography',
            icon: icon3
        },
        {
            id: 24,
            name: 'Bundles',
            icon: icon4
        },
        {
            id: 25,
            name: 'Audio streaming',
            icon: icon5
        },
        {
            id: 26,
            name: 'Subscriptions',
            icon: icon6
        },
        {
            id: 27,
            name: 'ChatGPT plugins',
            icon: icon3
        }, {
            id: 28,
            name: 'Videos',
            icon: icon6
        }, {
            id: 29,
            name: 'Education',
            icon: icon6
        }, {
            id: 30,
            name: 'Fiction books',
            icon: icon6
        }, {
            id: 31,
            name: 'Meal plans',
            icon: icon6
        },
        {
            id: 32,
            name: 'Online courses',
            icon: icon6
        }, {
            id: 33,
            name: '3D printing',
            icon: icon6
        }, {
            id: 34,
            name: 'Workout plans',
            icon: icon6
        }, {
            id: 35,
            name: 'Event tickets',
            icon: icon6
        }, {
            id: 36,
            name: 'Design',
            icon: icon6
        }, {
            id: 37,
            name: 'Recipes',
            icon: icon6
        },
    ]
    const items2 = [
        {
            id: 20,
            name: 'WhatsApp',
            icon: icon20
        },
        {
            id: 21,
            name: 'Anywhere you sell',
            icon: icon1
        },
        {
            id: 22,
            name: 'Notion templates',
            icon: icon2
        },
        {
            id: 23,
            name: 'Photography',
            icon: icon3
        },
        {
            id: 24,
            name: 'Bundles',
            icon: icon4
        },
        {
            id: 25,
            name: 'Audio streaming',
            icon: icon5
        },
        {
            id: 26,
            name: 'Subscriptions',
            icon: icon6
        },
        {
            id: 27,
            name: 'ChatGPT plugins',
            icon: icon6
        }, {
            id: 28,
            name: 'Videos',
            icon: icon6
        }, {
            id: 29,
            name: 'Education',
            icon: icon6
        }, {
            id: 30,
            name: 'Fiction books',
            icon: icon6
        }, {
            id: 31,
            name: 'Meal plans',
            icon: icon6
        },
        {
            id: 32,
            name: 'Online courses',
            icon: icon6
        }, {
            id: 33,
            name: '3D printing',
            icon: icon6
        }, {
            id: 34,
            name: 'Workout plans',
            icon: icon6
        }, {
            id: 35,
            name: 'Event tickets',
            icon: icon6
        }, {
            id: 36,
            name: 'Design',
            icon: icon6
        }, {
            id: 37,
            name: 'Recipes',
            icon: icon6
        },

        {
            id: 1,
            name: 'WordPress',
            icon: icon1
        },
        {
            id: 2,
            name: 'Wix',
            icon: icon2
        },
        {
            id: 3,
            name: 'Webflow',
            icon: icon3
        },
        {
            id: 4,
            name: 'Squarespace',
            icon: icon4
        },
        {
            id: 5,
            name: 'Notion',
            icon: icon5
        },
        {
            id: 6,
            name: 'Shopify',
            icon: icon6
        },
        {
            id: 7,
            name: 'software',
            icon: icon7
        },
        {
            id: 8,
            name: 'Instagram',
            icon: icon8
        },
        {
            id: 9,
            name: ' Stripe checkout',
            icon: icon9
        },
        {
            id: 10,
            name: 'Linktree',
            icon: icon10
        },
        {
            id: 11,
            name: 'Facebook',
            icon: icon11
        },
        {
            id: 12,
            name: 'Pinterest',
            icon: icon12
        },
        {
            id: 13,
            name: 'YouTube',
            icon: icon13
        },
        {
            id: 14,
            name: 'software',
            icon: icon14
        },
        {
            id: 15,
            name: 'TikTok',
            icon: icon15
        },
        {
            id: 16,
            name: ' X (Twitter)',
            icon: icon16
        },
        {
            id: 17,
            name: 'Payment links',
            icon: icon17
        },
        {
            id: 18,
            name: 'Your blog',
            icon: icon18
        },
        {
            id: 19,
            name: 'Your website',
            icon: icon19
        },

    ]
    const items3 = [
        {
            id: 10,
            name: 'Linktree',
            icon: icon10
        },
        {
            id: 11,
            name: 'Facebook',
            icon: icon11
        },
        {
            id: 12,
            name: 'Pinterest',
            icon: icon12
        },
        {
            id: 13,
            name: 'YouTube',
            icon: icon13
        },
        {
            id: 14,
            name: 'software',
            icon: icon14
        },
        {
            id: 15,
            name: 'TikTok',
            icon: icon15
        },
        {
            id: 16,
            name: ' X (Twitter)',
            icon: icon16
        },
        {
            id: 17,
            name: 'Payment links',
            icon: icon17
        },
        {
            id: 18,
            name: 'Your blog',
            icon: icon18
        },
        {
            id: 19,
            name: 'Your website',
            icon: icon19
        },
        {
            id: 20,
            name: 'WhatsApp',
            icon: icon20
        },
        {
            id: 21,
            name: 'Anywhere you sell',
            icon: icon1
        },

        {
            id: 22,
            name: 'Notion templates',
            icon: icon2
        },
        {
            id: 23,
            name: 'Photography',
            icon: icon3
        },
        {
            id: 24,
            name: 'Bundles',
            icon: icon4
        },
        {
            id: 25,
            name: 'Audio streaming',
            icon: icon5
        },
        {
            id: 26,
            name: 'Subscriptions',
            icon: icon6
        },
        {
            id: 27,
            name: 'ChatGPT plugins',
            icon: icon6
        }, {
            id: 28,
            name: 'Videos',
            icon: icon6
        }, {
            id: 29,
            name: 'Education',
            icon: icon6
        }, {
            id: 30,
            name: 'Fiction books',
            icon: icon6
        }, {
            id: 31,
            name: 'Meal plans',
            icon: icon6
        },
        {
            id: 32,
            name: 'Online courses',
            icon: icon6
        }, {
            id: 33,
            name: '3D printing',
            icon: icon6
        }, {
            id: 34,
            name: 'Workout plans',
            icon: icon6
        }, {
            id: 35,
            name: 'Event tickets',
            icon: icon6
        }, {
            id: 36,
            name: 'Design',
            icon: icon6
        }, {
            id: 37,
            name: 'Recipes',
            icon: icon6
        },
        {
            id: 1,
            name: 'WordPress',
            icon: icon1
        },
        {
            id: 2,
            name: 'Wix',
            icon: icon2
        },
        {
            id: 3,
            name: 'Webflow',
            icon: icon3
        },
        {
            id: 4,
            name: 'Squarespace',
            icon: icon4
        },
        {
            id: 5,
            name: 'Notion',
            icon: icon5
        },
        {
            id: 6,
            name: 'Shopify',
            icon: icon6
        },
        {
            id: 7,
            name: 'software',
            icon: icon7
        },
        {
            id: 8,
            name: 'Instagram',
            icon: icon8
        },
        {
            id: 9,
            name: ' Stripe checkout',
            icon: icon9
        },

    ]
    // Shuffle the items array
    // shuffleArray(items);

    // Split the shuffled array into three parts
    // const array1 = items.slice(0, 12);
    // const shuffledItems1 = items.slice(12, 24);
    // const shuffledItems2 = items.slice(24);
    // const shuffledItems1 = [...items].sort(() => Math.random() - 0.5);
    // const shuffledItems2 = [...items].sort(() => Math.random() - 0.5);
    const icons = [
        {
            id: 1,
            name: 'software',
            icon: icon40
        },
        {
            id: 2,
            name: 'software',
            icon: icon42
        },
        {
            id: 3,
            name: 'software',
            icon: icon43
        },

        {
            id: 4,
            name: 'software',
            icon: icon44
        },
        {
            id: 5,
            name: 'software',
            icon: icon45
        },

        {
            id: 6,
            name: 'Instagram',
            icon: icon46
        },
        {
            id: 7,
            name: 'software',
            icon: icon47
        },
        {
            id: 8,
            name: 'software',
            icon: icon48
        },
        {
            id: 9,
            name: 'software',
            icon: icon49
        },
        {
            id: 10,
            name: 'software',
            icon: icon50
        },
        {
            id: 11,
            name: 'software',
            icon: icon51
        },
        {
            id: 12,
            name: 'software',
            icon: icon52
        },
        {
            id: 13,
            name: 'software',
            icon: icon53
        },
        {
            id: 14,
            name: 'software',
            icon: icon54
        },
        {
            id: 15,
            name: 'software',
            icon: icon55
        },
        {
            id: 16,
            name: 'software',
            icon: icon56
        },
        {
            id: 17,
            name: 'software',
            icon: icon57
        },
        {
            id: 18,
            name: 'software',
            icon: icon58
        },
        {
            id: 19,
            name: 'software',
            icon: icon59
        },
        {
            id: 20,
            name: 'software',
            icon: icon60
        },
        {
            id: 21,
            name: 'software',
            icon: icon61
        },
        {
            id: 22,
            name: 'software',
            icon: icon62
        },

        {
            id: 23,
            name: 'Instagram',
            icon: icon63
        },

        {
            id: 24,
            name: 'software',
            icon: icon44
        },
        // {
        //     id: 24,
        //     name: 'software',
        //     icon: icon30
        // },
        // {
        //     id: 25,
        //     name: 'software',
        //     icon: icon31
        // },
        // {
        //     id: 26,
        //     name: 'software',
        //     icon: icon32
        // },
        // {
        //     id: 27,
        //     name: 'software',
        //     icon: icon33
        // },
        // {
        //     id: 28,
        //     name: 'software',
        //     icon: icon34
        // },
        // {
        //     id: 29,
        //     name: 'software',
        //     icon: icon35
        // },
        // {
        //     id: 30,
        //     name: 'software',
        //     icon: icon36
        // },
        // {
        //     id: 31,
        //     name: 'software',
        //     icon: icon37
        // },
        // {
        //     id: 32,
        //     name: 'software',
        //     icon: icon38
        // },
        // {
        //     id: 33,
        //     name: 'software',
        //     icon: icon39
        // },
    ]
    // const duplicatedCoins = [...icons, ...icons];
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setCoinIndex((prevIndex) => (prevIndex + 1) % duplicatedCoins.length);
    //     }, 1000);

    //     return () => clearInterval(intervalId);
    // }, []);
    // const items = [
    //     'Software',
    //     'Item 2',
    //     'Item 3',
    //     'Item 4',
    //     'Item 5',
    //     'Item 6',
    //     'Item 7',
    //     'Item 8',
    //     'Item 9',
    //     'Item 10',
    //     'Item 11',
    //     'Item 12',
    //     'Item 13',
    //     'Item 14',
    //     'Item 15',
    //     'Item 16',
    //     'Item 17',
    //     'Item 28',
    //     'Item 37',
    //     'Item 44',
    //     'Item 52',
    //     'Item 67',
    //     'Item 74',
    //     'Item 82',
    //     'Item 96',
    //     'Item 103',
    //     'Item 116',
    //     'Item 124',
    //     'Item 132',
    //     'Item 145',
    //     'Item 152',
    //     'Item 166',
    //     'Item 67',
    //     'Item 74',
    //     'Item 82',
    //     'Item 96',
    //     'Item 103',
    //     'Item 116',
    //     'Item 124',
    //     'Item 132',
    //     'Item 145',
    //     'Item 152',
    //     'Item 166',

    // ];
    return (

        <Fragment>
            <Header />
            <Services02 data={dataServices2} />
            <section className="banner swap-section">
                <div className="container">

                    <div className='swap-heading-lines'>
                        <div className='swap-heading-lines-inner-div'>
                            <img src={iconheading}></img><p>Get set up in 20 seconds</p>
                        </div>
                        <div className='swap-heading-lines-inner-div'>
                            <img src={iconheading}></img><p>Sell directly to your customers</p>
                        </div>
                        <div className='swap-heading-lines-inner-div'>
                            <img src={iconheading}></img><p>Get paid instantly</p>
                        </div>
                    </div>

                    <div>
                        <div className='heading-swap'>
                            <h1>Wherever you sell, whatever you sell ...<br /> we’ve got you covered</h1><button className='btn try-free-btn'>Try free</button>
                        </div>
                    </div>

                    <div className='swap-page-main-div'>
                        <div className='swapmain-div'>
                            <div className='swap-heading'>
                                <p>Anything you sell</p>
                            </div>

                            <div className="slider-container">
                                <div
                                    className="slider-track"
                                    style={{
                                        // animation: `scrollss 20s linear infinite`,
                                        // scrollSnapType: " x mandatory",
                                        // transform: `translateX(-${currentIndex * (100 / items.length)}%)`,
                                        // transition: 'transform 0.02s linear', // Adjust the transition duration
                                    }}
                                >
                                    {items.map((item) => (
                                        <div key={item.id} className="slider-item">
                                            <img src={item.icon} alt="" />
                                            <p>

                                                {item.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='swapmain-div'>
                            <div className='swap-heading'>

                                <p>Anyway you sell</p>
                            </div>

                            <div className="slider-container">
                                <div
                                    className="slider-track"
                                    style={{
                                        // animation: `scrollss 20s linear infinite`,
                                        // scrollSnapType: " x mandatory",
                                        // transform: `translateX(-${currentIndex * (100 / items.length)}%)`,
                                        // transition: 'transform 0.02s linear', // Adjust the transition duration
                                    }}
                                >
                                    {items2.map((item, index) => (
                                        <div key={item.id} className="slider-item">
                                            <img src={item.icon} alt="" />
                                            <p>

                                                {item.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='swapmain-div'>
                            <div className='swap-heading'>

                                <p>Anywhere you sell</p>
                            </div>

                            <div className="slider-container">
                                <div
                                    className="slider-track"
                                    style={{
                                            // animation: `scrollss 20s linear infinite`,
                                            // scrollSnapType: " x mandatory",
                                        // transform: `translateX(-${currentIndex * (100 / items.length)}%)`,
                                        // transition: 'transform 0.02s linear', 
                                    }}
                                >
                                    {items3.map((item, index) => (
                                        <div key={item.id} className="slider-item">
                                            <img src={item.icon} alt="" />
                                            <p>

                                                {item.name}
                                            </p>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className='getup-main' >
                <div className='row   getup-main-inner'>
                    <div className='col-md-6 center  get-up-col'>
                        <div>

                            <h1>Get up and running in a jiff</h1></div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card-container">
                            <div className="card-swap ">
                                <div className="icon-container-swap">

                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                                                <path fill="currentColor" fillRule="evenodd" d="M19 7h-1V6a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3M5 5h10a1 1 0 0 1 1 1v1H5a1 1 0 0 1 0-2m15 10h-1a1 1 0 0 1 0-2h1zm0-4h-1a3 3 0 0 0 0 6h1v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8.83A3 3 0 0 0 5 9h14a1 1 0 0 1 1 1z" clipRule="evenodd"></path>
                                            </svg> */}
                                </div>
                                <h6>How to Create Products</h6>
                                <div className='card-swap-inner'>
                                    watchVideo
                                </div>
                                {/* <p className='mt-2'>Keep your funds in a secure custodial wallet</p> */}
                            </div>
                            <div className="card-swap">
                                <div className="icon-container-swap">
                                    {/* Add your icon component or image here */}
                                    {/* <img src={icon1} alt="Icon 2" /> */}
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 10v8a2 2 0 0 1-2 2H5"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m16 11 3-3 3 3M5 14V6a2 2 0 0 1 2-2h12"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8 13-3 3-3-3"></path></svg> */}
                                </div>
                                <h6>How to Sell products</h6>
                                <div className='card-swap-inner'>
                                    watchVideo
                                </div>
                                {/* <p className='mt-2'>Trade and exchange cryptocurrencies</p> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <section className="banner swap-section">
                <div className="container">
                    <div className='animation-heading-main'>

                        <span>Concentrate on what you do best.</span>
                        <h1>We’ll do the rest</h1>


                    </div>
                    <div className='dotherest-main-div'>

                        {/* active-animatioons */}
                        <div className='dotherest-main    '>
                            <div className='dotherest-heading'>
                                <p className='pag-1'>You</p>
                                {/* <img src={ada} alt=''></img> */}
                                <p className='pag-2'>You</p>
                            </div>
                            <div className='dotherest-body'>
                                <div className='icon-paraghp'>
                                    <div className="centered-circle-icon1">

                                        <img src={icon_wand} alt="" />

                                    </div>
                                    <p >Create and upload products</p>
                                </div>
                                {/* <div>
                                    <img src={bnb} alt="" />
                                </div> */}
                                <div className='dotherest-ineer-body'>


                                    <div className='icon-paraghp' >
                                        <div className="centered-circle-icon">

                                            <img src={icon_card} alt="" />
                                        </div>
                                        <p>Integrate with payment gateways</p>

                                    </div>
                                    <div className='icon-paraghp'>
                                        <div className="centered-circle-icon">

                                            <img src={icon_chart} alt="" />
                                        </div>
                                        <p>Create conversion-optimized checkout</p>
                                    </div>
                                    <div className='icon-paraghp' >
                                        <div className="centered-circle-icon">

                                            <img src={icon_cube} alt="" />
                                        </div>
                                        <p>Ensure secure, automated digital delivery at scale</p>
                                    </div>
                                </div>
                                <div className='icon-paraghp'>
                                    <div className="centered-circle-icon2 ">

                                        <img src={icon_pay} alt="" />
                                    </div>
                                    <p >Get paid</p>
                                </div>
                            </div>

                        </div>
                        <div className='dotherest-main-borders active-animatioon'></div>
                        <div className=' border-large-animation active-animatioons'></div>
                    </div>
                </div>
            </section>
            <div className='swapmain-div2 '>
                <div className='swap-heading2'>
                    <h4>Available gateways and integrations</h4>
                </div>

                {/* <div className="slider-container">
                    <div
                        className="slider-track"
                        style={{
                            transform: `translateX(-${currentIndex * (100 / items.length)}%)`,
                            transition: 'transform 0.02s linear', // Adjust the transition duration
                        }}
                    >
                        {icons.map((item) => (
                            <div key={item.id} className="slider-item-coins">
                                <img src={item.icon} alt="" />
                                {item.name}
                            </div>
                        ))}
                    </div>
                </div> */}
                <div className="slider-container">
                    <div
                        className="d-flex"
                        style={{
                            // transform: `translateX(-${coinIndex * (100 / duplicatedCoins.length)}%)`,
                            // transition: 'transform 0.02s linear infinite',
                            animation: `scrollss 15s linear infinite`,
                            scrollSnapType: " x mandatory",
                        }}
                    >

                        {icons.map((item) => (
                            <div className="icon-container-swap" key={item.id}>
                                <div className='icon-container-swap-inner'>

                                    <div className="icon-item" style={{
                                        backgroundImage: `url(${item.icon})`,


                                    }}> </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Index