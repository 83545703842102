import React, { Fragment } from 'react'
import './About.css'
import icon1 from '../../assets/images/icon/icon-01.png'
import icon2 from '../../assets/images/icon/icon-02.png'
import icon3 from '../../assets/images/icon/icon-03.png'
const About03 = () => {
    return (
        <Fragment>
            <section>
                <div className="page-container">
            <div className="container">
                    <div className="content   card-main-div">
                        <h1 className="heading">Unlock the <br />full potential</h1>
                        <div className="card-container card-main-div-flex">
                            <div className="card">
                                <div className="icon-container">
                                    {/* Add your icon component or image here */}
                                    {/* <img src={icon1} alt="Icon 1" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                                        <path fill="currentColor" fillRule="evenodd" d="M19 7h-1V6a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3M5 5h10a1 1 0 0 1 1 1v1H5a1 1 0 0 1 0-2m15 10h-1a1 1 0 0 1 0-2h1zm0-4h-1a3 3 0 0 0 0 6h1v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8.83A3 3 0 0 0 5 9h14a1 1 0 0 1 1 1z" clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <h7>Wallet</h7>
                                <p className='mt-2'>Keep your funds in a secure custodial wallet</p>
                            </div>
                            <div className="card">
                                <div className="icon-container">
                                    {/* Add your icon component or image here */}
                                    {/* <img src={icon1} alt="Icon 2" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 10v8a2 2 0 0 1-2 2H5"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m16 11 3-3 3 3M5 14V6a2 2 0 0 1 2-2h12"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8 13-3 3-3-3"></path></svg>
                                </div>
                                <h7>Trade</h7>
                                <p className='mt-2'>Trade and exchange with a cryptocurrencies</p>
                            </div>
                            <div className="card">
                                <div className="icon-container">
                                    {/* Add your icon component or image here */}
                                    {/* <img src={icon1} alt="Icon 3" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0"></path></svg>
                                </div>
                                <h7>Payments</h7>
                                <p className='mt-2'>Accept cryptocurrency payments worldwide</p>
                            </div>
                            <div className="card">
                                <div className="icon-container">
                                    {/* Add your icon component or image here */}
                                    {/* <img src={icon1} alt="Icon 3" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"></path></svg>
                                </div>
                                <h7>Explorer</h7>
                                <p className='mt-2'>View  transaction status and  review addresses</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </Fragment>
    )
}

export default About03